import './App.css';
// import { inter } from './index.js'

import {
  Hero,
  LookBook,
  AboutUs,
  Testimonials,
  Navbar, 
  Footer,
  TeamMission,
  AutoPlayScroller
} from './Components/index'


function App() {
  return (
    <>

      <Navbar/>
      <Hero />
      
      <LookBook/>
      <AutoPlayScroller/>
      
      {/* <AboutUs/> */}
      <TeamMission/>
      <Testimonials/>
      <Footer/>
    </>
  );
}

export default App;
