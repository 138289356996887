import React from 'react'
import './Testimonials.css'


export const Testimonials = () => {
    return (
      <section id="reviews-566">
      <div class="cs-container">
          <div class="cs-content">
              {/* <span class="cs-topper">Our Reviews</span> */}
              <h2 class="cs-title">What Our Clients Say</h2>
              {/* <p class="cs-text">
                Read what others have to say about us
              </p> */}
          </div>
          <ul class="cs-card-group">
              <li class="cs-item">
                  {/* <!--Blue Quote--> */}
                  <img class="cs-stars" aria-hidden="true" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Reviews/stars3.svg" decoding="async" alt="quote icon" width="189" height="24"/>
                  <p class="cs-item-text">
                  Carmeta did my haircut and my highlights she did an amazing job I have curly hair so she just puffed up my hair a little bit I am so happy and my husband really loves it as well.
                  </p>
                  <div class="cs-flex-group">
                      {/* <picture class="cs-picture">
                          <img class="cs-profile" src="https://csimg.nyc3.digitaloceanspaces.com/Reviews/profile2.jpg" decoding="async" alt="profile" width="50" height="50" aria-hidden="true"/>
                      </picture> */}
                      <span class="cs-name">
                      Sarah Johnson
                          {/* <span class="cs-job">Psychologist</span> */}
                      </span>
                  </div>
              </li>
              <li class="cs-item">
                  {/* <!--Blue Quote--> */}
                  <img class="cs-stars" aria-hidden="true" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Reviews/stars3.svg" decoding="async" alt="quote icon" width="189" height="24"/>
                  <p class="cs-item-text">
                  This place is AWESOME! We are from North Dakota. Came here for vacation and wanted a day of beauty. Not knowing the area, we asked a gal at the manatee museum where she got her hair done and she told us, go to Internationally U Hair Salon! Best advise EVER!! Everyone there was so friendly, knowledgeable and helpful!! They do cut, color and nails!! Now we can blend in with the local beach goin’ crowd!! Thanks to Kristen Montalto and Carmeta Hulett for a great experience!
                  </p>
                  <div class="cs-flex-group">
                      {/* <picture class="cs-picture">
                          <img class="cs-profile" src="https://csimg.nyc3.digitaloceanspaces.com/Reviews/profile2.jpg" decoding="async" alt="profile" width="50" height="50" aria-hidden="true"/>
                      </picture> */}
                      <span class="cs-name">
                      Lorene Keehr
                          {/* <span class="cs-job">Business analyst</span> */}
                      </span>
                  </div>
              </li>
          </ul>
      </div>
  </section>
    )
  }
