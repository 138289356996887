import React from 'react'
import './LookBook.css'

import AutoScroll from 'embla-carousel-auto-scroll'
import useEmblaCarousel from 'embla-carousel-react'




export const LookBook = () => {
  return (
    <section class='lookbook-section'>
      <div className="text-container">
        <h2 className='lookbook-header'>See Our Styles</h2>
      </div>
    </section>
  )
  }