import React from 'react'
import './Hero.css'

export const Hero = () => {
  return (
    <section className="section-container">
      <div className='hero-container'>


  {/* Sub-header */}
  <p className='hero-paragraph'>
          Internationally U 
        </p>
        {/* Header */}
        <h1 className="hero-title">The Look You Want <br/>
                                      Without The Hassle</h1>
        
        {/* Sub-header 
        <p className='hero-paragraph'>
          Internationally U 
        </p>
        */}
        {/* Button Container */}
        <div className='button-container'>
          <button 
          className='primary-button' 
          role='button'><a href="tel:+7724646333">Call Now</a></button>
          {/* <button class='secondary-button' role='button'>See Services</button> */}
        </div>

      </div>  
      
    </section>
  )
}
