import React from 'react'
import './embla.css'

import AutoScroll from 'embla-carousel-auto-scroll'
import useEmblaCarousel from 'embla-carousel-react'
import Image1 from '../../assets/image-1.jpg'
import Image2 from '../../assets/image-2.jpeg'
import Image3 from '../../assets/image-3.jpeg'
import Image4 from '../../assets/image-4.jpeg'
import Image5 from '../../assets/image-5.jpeg'
import Image6 from '../../assets/image-6.jpg'
import Image7 from '../../assets/image-7.JPG'
import Image8 from '../../assets/image-8.JPG'




export const AutoPlayScroller = () => {

    const [emblaRef] = useEmblaCarousel({ loop: true }, [
      AutoScroll({ stopOnInteraction: true })
    ])    
    
    return (
    <>
      {/* EMBLA CAROUSEL */}
      <div className="embla" ref={emblaRef}>
        <div className="embla__container">
          <div className="embla__slide">
            <img src={Image1} alt="Slide 1" />
          </div>
          <div className="embla__slide">
            <img src={Image2} alt="Slide 1" />
          </div>
          <div className="embla__slide">
            <img src={Image3} alt="Slide 1" />
          </div>
          <div className="embla__slide">
            <img src={Image4} alt="Slide 1" />
          </div>
          <div className="embla__slide">
            <img src={Image5} alt="Slide 1" />
          </div>
          <div className="embla__slide">
            <img src={Image6} alt="Slide 1" />
          </div>
          <div className="embla__slide">
            <img src={Image7} alt="Slide 1" />
          </div>
        </div>
      </div>
    </>
)}