import React from 'react'
import './TeamMission.css'

import HS1 from '../../assets/aboutUs/carmeta.jpg'
import HS2 from '../../assets/aboutUs/carrie.jpg'
import HS3 from '../../assets/aboutUs/debbie.jpg'
import HS4 from '../../assets/aboutUs/kristen.jpg'
import HS5 from '../../assets/aboutUs/bethany.jpg'
import HS6 from '../../assets/aboutUs/jennifer.jpg'



export const TeamMission = () => {
  return (
    <>
<section id="meet-us-1021">
    <div class="cs-container">
        <div class="cs-content">
            {/* <img class="cs-graphic" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/Ornament.svg" alt="chef" width="456" height="31"/> */}
            <h2 class="cs-title">Our Team</h2>
                <p className='text-white'> Internationally U Hair Salon is a unisex salon offering a wide range of services.
                    We provide top qaulity products and services, since 2016! </p>
                <br/>
                <p className='text-white'>
                    We strive to create a clean, positive, safe environment and maintain a good relationship
                    with our customers!
                </p>
                <br/>
                <p className='text-white'>
                - "We strive to do our best so you can look your best!"
                </p>
        </div>
        <ul class="cs-card-group">
            {/* Carmeta  */}
            <li class="cs-item">
                <picture class="cs-picture">
                    <img class='carmeta-img' aria-hidden="true" loading="lazy" decoding="async" src={HS1} alt="chef" width="305" height="335"/>
                </picture>
                <div class="cs-info">
                    <span class="cs-name">Carmeta</span>
                </div>
            </li>

            {/* Carrie */}
            <li class="cs-item">
                <picture class="cs-picture">
                    <img class='carrie-img' aria-hidden="true" loading="lazy" decoding="async" src={HS2} alt="chef" width="305" height="335"/>
                </picture>
                <div class="cs-info">
                    <span class="cs-name">Carrie</span>
                </div>
            </li>
          
          
          {/* Debbie  */}
            <li class="cs-item">
                <picture class="cs-picture">
                    <img class='default-img' aria-hidden="true" loading="lazy" decoding="async" src={HS3} alt="chef" width="305" height="335"/>
                </picture>
                <div class="cs-info">
                    <span class="cs-name">Debbie</span>
                </div>
            </li>

             {/* Kristen */}
             <li class="cs-item">
                <picture class="cs-picture">
                    <img  class='position-img' aria-hidden="true" loading="lazy" decoding="async" src={HS4} alt="chef" width="305" height="335"/>
                </picture>
                <div class="cs-info">
                    <span class="cs-name">Kristen</span>
                </div>
            </li>

             {/* Bethany */}
             <li class="cs-item">
                <picture class="cs-picture">
                    <img  class='defualt-img' aria-hidden="true" loading="lazy" decoding="async" src={HS5} alt="chef" width="305" height="335"/>
                </picture>
                <div class="cs-info">
                    <span class="cs-name">Bethany</span>
                </div>
            </li>

             {/* Jennifer */}
             <li class="cs-item">
                <picture class="cs-picture">
                    <img  class='default-img' aria-hidden="true" loading="lazy" decoding="async" src={HS6} alt="chef" width="305" height="335"/>
                </picture>
                <div class="cs-info">
                    <span class="cs-name">Jennifer</span>
                </div>
            </li>

        </ul>
    </div>
</section>
    </>
  )
}
