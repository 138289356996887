import React from 'react'
import './Footer.css'

export const Footer = () => {
  return (
    <footer id="footer-1147">
    <div class="cs-container">
        <div class="cs-top">
            {/* <a aria-label="go back to home" class="cs-logo" href="">
                <img class="cs-logo-img" loading="lazy" decoding="async" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Logos/charity-logo.svg" alt="logo" width="168" height="48"/>
            </a> */}
            {/* <ul class="cs-ul">
                <li class="cs-li">
                    <a href="" class="cs-link">Home</a>
                </li>
                <li class="cs-li">
                    <a href="" class="cs-link">About</a>
                </li>
                <li class="cs-li">
                    <a href="" class="cs-link">Services</a>
                </li>
                <li class="cs-li">
                    <a href="" class="cs-link">Donations</a>
                </li>
                <li class="cs-li">
                    <a href="" class="cs-link">Events</a>
                </li>
                <li class="cs-li">
                    <a href="" class="cs-link">Blog</a>
                </li>
                <li class="cs-li">
                    <a href="" class="cs-link">Contact</a>
                </li>
            </ul>*/}
        </div> 
        <div class="cs-bottom">
            {/* <!--Social--> */}
            <ul class="cs-social">
                {/* <li class="cs-social-li">
                    
                    <a href="" class="cs-social-link" aria-label="facebook" target="_blank" rel="noopener">
                        
                        <img class="cs-social-icon cs-default" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/facebook-white.svg" alt="icon" loading="lazy" decoding="async" width="12" height="12" aria-hidden="true"/>
                    </a>
                </li> */}
                {/* <li class="cs-social-li">
                    <a href="" class="cs-social-link" aria-label="twitter" target="_blank" rel="noopener">
                        <img class="cs-social-icon cs-default" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/twitter-white.svg" alt="icon" loading="lazy" decoding="async" width="12" height="12" aria-hidden="true"/>
                    </a>
                </li>
                <li class="cs-social-li">
                    <a href="" class="cs-social-link" aria-label="instagram" target="_blank" rel="noopener">
                        <img class="cs-social-icon cs-default" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/instagram-transparent.svg" alt="icon" loading="lazy" decoding="async" width="12" height="12" aria-hidden="true"/>
                    </a>
                </li> */}
                {/* <li class="cs-social-li">
                    <a href="" class="cs-social-link" aria-label="twitter" target="_blank" rel="noopener">
                        <img class="cs-social-icon cs-default" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/youtube-transparent2.svg" alt="icon" loading="lazy" decoding="async" width="12" height="12" aria-hidden="true"/>
                    </a>
                </li> */}
            </ul>
            <span class="cs-copyright">
                <h1 class="">Internationally-U</h1>
            </span>
        </div>
    </div>
    {/* <!--Background--> */}
    {/* <picture class="cs-background"> */}
        {/* <!--Mobile Image--> */}
        {/* <source media="(max-width: 600px)" srcset="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/People/kids2.jpg"/> */}
        {/* <!--Tablet and above Image--> */}
        {/* <source media="(min-width: 601px)" srcset="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/People/kids2.jpg"/>
        <img loading="lazy" decoding="async" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/People/kids2.jpg" alt="kids" width="1280" height="568"/>
    </picture> */}
</footer>
  )
}

