import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

import services from '../../assets/services.png'

export const Navbar = () => {
  return (
    
<nav className="navbar-section">
    <div className="navbar-contents">
        
        <div className="navbar-brand">
            <img src="/IU_logo.png" alt='internationally-U Logo'/>
        </div>
        
        <div className="nav-button-container">
            <button onClick={() => window.open(services, '_blank')} className="navbar-button">
                See Services
            </button>
        </div>
    </div>
</nav>
  )
}
